import React, { useState, useEffect, useRef } from "react";
import { ErrorMessage } from "formik";
import TextError from "../TextError/TextError";
import "../Input/Input.scss";
import "./Select.scss";

const Select = ({
  optionsList,
  defaultText,
  label,
  formik,
  name,
  variant,
  onClickCallback,
  disabled,
  background,
}) => {
  const [defaultSelectText, setDefaultSelectText] = useState("");
  const [showOptionList, setShowOptionList] = useState(false);
  const selectContainerRef = useRef(null);
  // const handleClickOutside = (e) => {
  //   if (
  //     !e.target.classList.contains("custom-select-option") &&
  //     !e.target.classList.contains("selected-text")
  //   ) {
  //     setShowOptionList(false);
  //   }
  // };

  // const handleListDisplay = () => {
  //   setShowOptionList((prevState) => !prevState);
  // };

  const handleOptionClick = (option) => {
    // let selectedValue = e.target.getAttribute("data-name");
    setDefaultSelectText(option.name);
    onClickCallback(option, name);
    setShowOptionList(false);
  };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    setDefaultSelectText(defaultText);
  }, [defaultText]);

  return (
    <div
      className={`common_input ${variant} ${
        formik.values[name] ? "hasFilled" : ""
      } ${
        formik?.touched[name] && formik?.errors[name]
          ? "common_input--error"
          : ""
      } `}
      controlId={name}
      style={{ pointerEvents: disabled, backgroundColor: background }}
    >
      {label && <label className="form-label">{label}</label>}
      <div
        className="global_select common_input_inner"
        ref={selectContainerRef}
        onMouseLeave={() => {
          setShowOptionList(false);
        }}
      >
        <div
          className={`select ${
            showOptionList ? "selected-text active" : "selected-text"
          }`}
          // onClick={handleListDisplay}
          onMouseEnter={() => setShowOptionList(true)}
          style={{ backgroundColor: background }}
        >
          <p>{defaultSelectText}</p>
        </div>
        {showOptionList && (
          <ul className="global_select_options">
            {optionsList?.map((option) => (
              <li
                className="custom-select-option"
                data-name={option.name}
                key={option.id}
                onClick={() => handleOptionClick(option)}
              >
                {option.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Select;
