import React from 'react';
import "./CommonTable.scss";
import { Table } from 'react-bootstrap';
import PerfectScrollbar from "react-perfect-scrollbar";

const CommonTable = ({ className, serialised, children }) => {
    return (
        <>
            <div className={`common_table ${serialised ? "serialised" : ""} ${className || ""}`}>
                <PerfectScrollbar>
                    <Table responsive>
                        {children}
                    </Table>
                </PerfectScrollbar>
            </div>
        </>
    );
};

export default CommonTable;