import React, { useEffect, useState } from "react";
import Filter from "../../../common/Filter/Filter";
import { CommonBtn } from "../../../common/ui";
import CommonTable from "../../../common/ui/CommonTable/CommonTable";
import "./Product.scss";
import DeleteModal from "../../../common/ui/Modals/DeleteModal/DeleteModal";
import { useSelector } from "react-redux";
import { apiCallDelete, apiCallGet } from "../../../../axios/axios";
import { API_DATA_LIMIT, PAGES, PLACEHOLDER_TEXT } from "../../../../utils/constants";
import { titleCase } from "../../../../utils/utils";
import PaginationWrapper from "../../../common/ui/PaginationWrapper/PaginationWrapper";
import ProductModal from "../../../common/ui/Modals/UpdateProductModal/ProductModal";

const Product = () => {
  // states
  const [blogData, setBlogData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [itemToDelete, setItemToDelete] = useState("");
  const [itemToUpdate, setItemToUpdate] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [combinedProducts, setCombinedProducts] = useState({});
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectName,setSelectName] = useState('')
  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedImage, setSelectImage] = useState(null)
  // hooks
  const { blog } = useSelector((state) => state.filters);

  // get blogs
  const getproductList = async () => {
    let res = await apiCallGet(
      `/api/v1/satyug/product/all/${API_DATA_LIMIT}/${offset}`,
      {},
      false,
      true
    );
    if (!res?.error) {
      setBlogData(res?.data);
    }
  };

  const onBlogSuccessCallback = () => {
    getproductList();
  };

  useEffect(() => {
    getproductList();
  }, [blog, offset]);

  // delete blog
  const deleteProduct = async () => {
    let res = await apiCallDelete(
      `/api/v1/satyug/product/${itemToDelete}`,
      {},
      {},
      true,
      true
    );
    if (!res?.error) {
      setItemToDelete("");
      getproductList();
    }
  };
  const deleteHandler = (item) => setItemToDelete(item);

  const submitCallback = () => {
    deleteProduct();
  };

  const getCombinedProductsByTitle = (data) => {
    const combinedProducts = {};
    data.forEach((item) => {
      if (!combinedProducts[item.type]) {
        combinedProducts[item.type] = [item];
      } else {
        combinedProducts[item.type].push(item);
      }
    });
    return combinedProducts;
  };

  useEffect(() => {
    getproductList();
  }, [blog, offset]);

  useEffect(() => {
    setCombinedProducts(getCombinedProductsByTitle(blogData?.rows || []));
  }, [blogData]);
  useEffect(() => {
    const selectedProduct = combinedProducts["t-shirt"]?.find((item) => item.size === selectedSize);
    const firstTime = combinedProducts['t-shirt']?.[0]

    if (selectedProduct) {
      setSelectedPrice(selectedProduct?.price || "--");
      setSelectedQuantity(selectedProduct?.quantity || "--");
      setSelectImage(selectedProduct?.image || "--");
      setSelectName(selectedProduct?.title || "--");
    } else {
      setSelectedPrice(firstTime?.price);
      setSelectedQuantity(firstTime?.quantity);
      setSelectImage(firstTime?.image)
      setSelectName(firstTime?.title);
    }
  }, [selectedSize, combinedProducts]);

  const handleHide = () => {
    setItemToUpdate(false);
    setSelectedItemId(null); // Reset selectedItemId
  };

  return (
    <div className="products_page">
      {blogData?.count >= 6 ? "" :
        <Filter
          // isSearch
          // isStartdate
          // isToDate
          isButton
          onClick={() => setShowAdd(true)}
          title={<p>Add Product</p>}
          page={PAGES.blog}
          placeholdertext={PLACEHOLDER_TEXT.title}
        />
      }
      <CommonTable serialised={true} className="products_page_table">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Name</th>
            <th>Image</th>
            <th>Size</th>
            <th>Price</th>
            <th>Quantity</th>
            <th style={{ width: "330" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(combinedProducts).length === 0 ? (
            <tr>
              <td colSpan={7} className="no_data_found">
                No data found
              </td>
            </tr>
          ) : (
            Object.entries(combinedProducts).map(([title, items], index) => {
              const firstItem = items[0];
              const titleCount = items.length;
              const firstTime = combinedProducts['t-shirt']?.[0]
             
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>{offset * 10 - 10 + index + 1}</td>
                    <td className="dark_clr">
                    <td>{titleCount > 1 ? selectName : firstItem?.title || "--"}</td>
                      {/* {titleCount > 1 && ` (${titleCount})`} */}
                    </td>
                    <td>
                      <img src={titleCount > 1 ? selectedImage : firstItem?.image} style={{ width: "50px" }} alt='' />
                    </td>
                    <td>
                      {titleCount > 1 ? (
                        <select
                          value={selectedSize}
                          onChange={(e) => {
                            setSelectedSize(e.target.value);
                            const selectedItem = items?.find((item) => item.size === e.target.value);
                            setSelectedItemId(selectedItem?.id ? selectedItem.id : null);
                          }}
                        >
                          <p>{firstItem?.size}</p>
                          {items.map((item, index) => (
                            <option key={index} value={item?.size}>
                              {item?.size}
                            </option>
                          ))}
                        </select>
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>{titleCount > 1 ? selectedPrice : firstItem.price || "--"}</td>
                    <td>{titleCount > 1 ? selectedQuantity : firstItem.quantity || "--"}</td>
                    <td>
                      {titleCount > 0 && (
                        <button
                          className="update_btn"
                          onClick={() => setItemToUpdate({ id: selectedItemId ? selectedItemId : items[0]?.id, selectedSize })}
                        >
                          Update
                        </button>
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })
          )}
        </tbody>
      </CommonTable>


      <DeleteModal
        title={"Are you sure you want to delete this product"}
        show={itemToDelete}
        onHide={() => setItemToDelete("")}
        onSubmit={submitCallback}
      />

      {showAdd && (
        <ProductModal
          combinedProducts={combinedProducts}
          show={showAdd}
          onHide={() => setShowAdd(false)}
          onSuccess={onBlogSuccessCallback}
        />
      )}

      {itemToUpdate && (
        <ProductModal
          combinedProducts={combinedProducts}
          show={itemToUpdate}
          onHide={() => handleHide()}
          onSuccess={onBlogSuccessCallback}
          isUpdate={true}
        />
      )}

      {blogData?.count > 0 && (
        <div className="page_nation">
          <PaginationWrapper
            limit={API_DATA_LIMIT}
            page={offset}
            count={blogData?.count}
            onChange={(e) => setOffset(e)}
          />
        </div>
      )}
    </div>
  );
};

export default Product;
