import React, { useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import "./CollectiveListTab.scss";
import ImageModal from "../../../../common/ui/Modals/imageModal/ImageModal";
import CommonTable from "../../../../common/ui/CommonTable/CommonTable";
import { API_DATA_LIMIT } from "../../../../../utils/constants";
import PaginationWrapper from "../../../../common/ui/PaginationWrapper/PaginationWrapper";
import { checkUndefiendValue, truncateStringToWords } from "../../../../../utils/utils";
import Filter from "../../../../common/Filter/Filter";
import CollectibleModal from "../../../../common/ui/Modals/CollectibleModal/CollectibleModal";
import { DeleteIcon, EditIcon } from "../../../../../assets/images/svg/SvgIcon";
import DeleteModal from "../../../../common/ui/Modals/DeleteModal/DeleteModal";
import { apiCallDelete } from "../../../../../axios/axios";

const CollectiveListTab = ({ listNft, getCollectible ,offset }) => {

  // const [offset, setOffset] = useState(1);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [itemToUpdate, setItemToUpdate] = useState("");
  const [itemToDelete, setItemToDelete] = useState("");

  const [showAdd, setShowAdd] = useState(false);

  const showImageModal = (image) => {
    setSelectedImage(image);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };
  const onBlogSuccessCallback = () => {
    getCollectible();
  };
  const deleteCollectible = async () => {
    let res = await apiCallDelete(
      `/api/v1/satyug/collectable/${itemToDelete}`,
      {},
      {},
      true,
      true
    );
    if (!res?.error) {
      setItemToDelete("")
      getCollectible();
    }
  };
  const handleHide = () => {
    setItemToUpdate(false);
  };
  const submitCallback = () => {
    deleteCollectible();
  };
  const deleteHandler = (item) => setItemToDelete(item);
  return (
    <section className="collectibles_page">
      <Container fluid>
        <Row>
          <Col>
            <CommonTable serialised={true}>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Category</th>
                  <th>Description</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {listNft?.length > 0 ? (
                  listNft?.map((item, index) => (
                    <tr key={index}>
                      <td>{offset * 10 - 10 + index + 1}</td>
                      <td>
                        <img onClick={() => showImageModal(item?.image)} src={item?.image} style={{ height: "50px", width: "50px" }} alt="img--123" />
                      </td>
                      <td>{checkUndefiendValue(item?.title, '--')}</td>
                      <td>{checkUndefiendValue(item?.collectableType?.name, '--')}</td>
                      <td>
                        <span className="contact_ques">{checkUndefiendValue(truncateStringToWords(item?.description, 5), '--')}
                        </span>
                      </td>
                      {/* <td>
                        <div className="action_box">
                          <button
                            onClick={() => setItemToUpdate(item)}
                            className="action_btn"
                          >
                            <EditIcon />
                          </button>
                          <button
                            className="action_btn"
                            onClick={() => deleteHandler(item?.id)}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="no_data_found">
                      No data found
                    </td>
                  </tr>
                )}

              </tbody>
            </CommonTable>
            {/* {listNft?.count > API_DATA_LIMIT && (
              <div className="page_nation">
                <PaginationWrapper
                  limit={API_DATA_LIMIT}
                  page={offset}
                  count={listNft?.count}
                  onChange={(e) => setOffset(e)}
                />
              </div>
            )} */}
            {isImageModalOpen && (
              <ImageModal
                show={isImageModalOpen}
                onHide={closeImageModal}
                hotelDetail={listNft}
                image={selectedImage}

              />
            )}
            {itemToUpdate && (
              <CollectibleModal
                show={itemToUpdate}
                onHide={() => handleHide()}
                onSuccess={onBlogSuccessCallback}
                isUpdate={true}
              />
            )}
            <DeleteModal
              title={"Are you sure you want to delete this Collectible"}
              show={itemToDelete}
              onHide={() => setItemToDelete("")}
              onSubmit={submitCallback}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CollectiveListTab;
