import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconItem2 from "../../../../assets/images/icons/logout-icon.svg";
import iconItem from "../../../../assets/images/icons/profile-icon.svg";
import { HamburgerIcon } from "../../../../assets/images/svg/SvgIcon";
import useLogout from "../../../../customHooks/useLogout";
import Customdropdown from "../Customdropdown/Customdropdown";
import "./Header.scss";

const Header = ({ handleSidebar, showSidebar }) => {
  const { pathname } = useLocation();
  const amountoptions = [
    {
      // to: "/admin/profile-setting",
      value: "setting",
      label: "Setting",
      iconItem: iconItem,
    },
    {
      // to: "/login",
      value: "logout",
      label: "Logout",
      iconItem: iconItem2,
    },
  ];

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);

  // animate on route change
  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const [logout] = useLogout();

  const dropdownClickHandler = (menu) => {
    switch (menu) {
      case "setting":
        navigate("/admin/setting");
        break;
      case "logout":
        logout();
        break;
      default:
        break;
    }
  };

  // const stringToLinkText = (string) => {
  //   let newStr = string.split("-").map((item) => {
  //     return item[0].toUpperCase() + item.slice(1);
  //   });
  //   return newStr.join(" ");
  // };
  const Titile = {
    "/admin/dashboard": "Dashboard",
    "/admin/user-management": "User Management",
    "/admin/collectible": "Collectibles",
    "/admin/collectibleType":"Collectibles Type",
    "/admin/sub-category":"Sub-Collectibles-Category",
    "/admin/Quiz": 'Quiz',
    "/admin/product": "Product",
    "/admin/setting": "Setting",
    "/admin/donation": "Donation",
    "/admin/thali": "Thali",
    "/admin/Quiz": "Quiz",
    "/admin/order": "Order",
    "/admin/contacts": "Query",
    "/admin/QuizLeaderBoard": "Quiz Leaderboard"
  };
  const [scrolled, setScroll] = useState(false);
  window.onscroll = () => {
    setScroll(window.scrollY > 0)
  }
  return (
    <>
      <div className={`top_header ${scrolled ? "scrolled" : ""}`}>
        <div className="heading">{Titile[pathname]}</div>
        <div className="header_right_links">
          {/* <Link to="/admin/wallet-list" className="wallet_address">
            <p>
              <span>
                <img src={wallet_icon} alt="wallet-icon" />
              </span>
              Wallet Balance:
            </p>
            <p className="balance_value">$20,000</p>
          </Link> */}
          <Customdropdown
            icon={userData?.image ? userData?.image : ""}
            title={'Admin'}
            className="header_dropdown"
            iconItem={iconItem}
            onClick={dropdownClickHandler}
            dropdownItems={amountoptions}
          />
          <button
            className="header_toggle"
            onClick={handleSidebar}
          >
            <HamburgerIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
