import * as CryptoJS from "crypto-js";
import moment from "moment";
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
//encrypting payload for server
function encryption(payload) {
  try {
    if (payload !== undefined || payload !== null) {
      var ciphertext = CryptoJS.AES.encrypt(payload, ENCRYPTION_KEY).toString();

      return ciphertext;
    }
  } catch (error) {
    return error;
  }
}

//decrypting response from server
function decryption(payload) {
  try {
    if (payload) {
      var decryptedText = CryptoJS.AES.decrypt(payload, ENCRYPTION_KEY);
      var decryptData = decryptedText.toString(CryptoJS.enc.Utf8);

      return decryptData;
    }
  } catch (error) {}
}

export { encryption, decryption };

//debouncing function to call function after 500ms
export const debouncing = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

//titlecase any letter
export function titleCase(str) {
  if (str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }
}

// format date
export const formatDate = (date, isTime) => {
  if (date) {
    return moment(date).format(`DD-MM-YYYY${isTime ? ", HH:mm" : ""}`);
  }
};

// return suitable value if the value is undefined
export const checkUndefiendValue = (data, returnValue) => {
  if (data) {
    return data;
  } else {
    return returnValue;
  }
};

export function getEmailDisplay(email) {
  if (email && typeof email === 'string') { // Check if email is defined and a string
    const atIndex = email.indexOf('@');
    if (atIndex !== -1) {
      const username = email.substring(0, atIndex);
      const domain = email.substring(atIndex);
      let truncatedUsername = username;
      if (username.length > 15) {
        truncatedUsername = username.substring(0, 8) + '...';
      }
      return truncatedUsername + domain;
    }
  }
  return '';
}

export function truncateName(firstName, lastName) {
  const fullName = `${firstName} ${lastName}`.trim();
  // if (fullName.length > 18) {
  //   return `${fullName.substring(0, 15)}...`;
  // }
  return fullName;
}

export function Address(address,city,pincode,country) {
  const fullAddress = `${address}, ${city}, ${pincode}, ${country}  `
   return fullAddress
}


export const getStatus = (status) => {
  let result = {};

  switch (status) {
    case 'SUCCESS':
      result = { status: 'Success' };
      break;
    case 'FAILED':
      result = { status: 'Failed' };
      break;
    case 'PENDING':
      result = { status: 'Pending' };
      break;
    default:
      result = { status: null };
      break;
  }

  return result;
};


 export function truncateStringToWords(inputString, numWords) {
  const words = inputString.split(' ');

  if (words.length <= numWords) {
    return inputString;
  }

  const truncatedWords = words.slice(0, numWords);
  return truncatedWords.join(' ') + '...';
}