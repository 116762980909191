import React, { memo, useEffect, useState } from "react";
import "./CollectibleType.scss";
import "../UserManagement/UserManagement.scss";
import { Col, Container, Row } from "react-bootstrap";
import CommonTable from "../../../common/ui/CommonTable/CommonTable";
import { apiCallDelete, apiCallGet, apiCallPost, } from "../../../../axios/axios";
import {
  checkUndefiendValue,
  getEmailDisplay,
  truncateName,
} from "../../../../utils/utils";
import {
  API_DATA_LIMIT,
  PAGES,
  PLACEHOLDER_TEXT,
} from "../../../../utils/constants";
import PaginationWrapper from "../../../common/ui/PaginationWrapper/PaginationWrapper";
import moment from "moment";
import Filter from "../../../common/Filter/Filter";
import CollectibleTypeModal from "../../../common/ui/Modals/CollectibleTypeModal/CollectibleTypeModal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCollectibleTypes } from "../../../../redux/user";
import { DeleteIcon, EditIcon } from "../../../../assets/images/svg/SvgIcon";
import DeleteModal from "../../../common/ui/Modals/DeleteModal/DeleteModal";

const CollectibleType = () => {
  const [collectibleType, setCollectibleType] = useState({});
  const [itemToDelete, setItemToDelete] = useState("");
  const [itemToUpdate, setItemToUpdate] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [offset, setOffset] = useState(1);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const getContactListType = async () => {
    let res = await apiCallPost(
      `/api/v1/satyug/collectableType/${API_DATA_LIMIT}/${offset}`,
      '',
      {},
      false,
      true
    );
    if (!res?.error) {
      setCollectibleType(res?.data);
    }
  };

  const onBlogSuccessCallback = () => {
    getContactListType();
  };

  useEffect(() => {
    getContactListType()
  }, [offset])

  const okok = (item) => {
    dispatch(setCollectibleTypes(item))
  };


  const deleteCollectible = async () => {
    let res = await apiCallDelete(
      `/api/v1/satyug/collectableType/${itemToDelete}`,
      {},
      {},
      true,
      true
    );
    if (!res?.error) {
      setItemToDelete("")
      getContactListType();
    }
  };
  const deleteHandler = (item) => setItemToDelete(item);

  const submitCallback = () => {
    deleteCollectible();
  };
  const handleHide = () => {
    setItemToUpdate(false);
  };
  return (
    <>
      <section className="collectible_type_page">
       
        { collectibleType?.count <= 0 ?
        <Filter
          isButton
          onClick={() => { setShowAdd(true) }}
          title={<p>Add Collectible Type</p>}
          page={PAGES.collectibleType}
          placeholdertext={PLACEHOLDER_TEXT.title}
        />
           : ""}
        <Container fluid>
          <Row>
            <Col>
              <CommonTable serialised={true} className="user_management_table">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Image</th>
                    <th>Category</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {collectibleType?.count > 0 ? (
                    collectibleType?.rows?.map((item, index) => (
                      <tr key={index}>
                        <td>{offset * 10 - 10 + index + 1}</td>
                        <td><img style={{ width: '50px', height: '50px' }} src={item?.image} alt="image" /></td>
                        <td>
                          {checkUndefiendValue(
                            item?.name, "--"
                          )}
                        </td>
                        <td>
                          {checkUndefiendValue(item?.description, '--')}
                        </td>
                        <td>
                          <div className="action_box">
                            <button
                              className="sub_category_btn"
                              onClick={() => {
                                navigate("/admin/sub-category");
                                okok(item);
                              }}>
                              Sub-Category
                            </button>
                            {/* <button
                              className="action_btn"
                              onClick={() => deleteHandler(item?.id)}
                            >
                              <DeleteIcon />
                            </button>
                            <button
                              className="action_btn"
                              onClick={() => setItemToUpdate(item?.id)}
                            >
                              <EditIcon />
                            </button> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="no_data_found">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </CommonTable>
              {collectibleType?.count > 0 && (
                <div className="page_nation">
                  <PaginationWrapper
                    limit={API_DATA_LIMIT}
                    page={offset}
                    count={collectibleType?.count}
                    onChange={(e) => setOffset(e)}
                  />
                </div>
              )}
              {showAdd && (
                <CollectibleTypeModal
                  // combinedProducts={combinedProducts}
                  show={showAdd}
                  onHide={() => setShowAdd(false)}
                  onSuccess={onBlogSuccessCallback}
                />
              )}
              {itemToUpdate && (
                <CollectibleTypeModal
                  show={itemToUpdate}
                  onHide={() => handleHide()}
                  onSuccess={onBlogSuccessCallback}
                  isUpdate={true}
                />
              )}
              <DeleteModal
                title={"Are you sure you want to delete this Category"}
                show={itemToDelete}
                onHide={() => setItemToDelete("")}
                onSubmit={submitCallback}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default memo(CollectibleType);
