import React from 'react'
import './Collective.scss'
import CollectiveTab from './CollectiveTab';

const Collective = () => {
    return (
        <div className='NFT_Management'>
            <div className='nft_heading'>
                <div className='nft_mange_tab'>
                    <CollectiveTab />
                </div>
            </div>
        </div>
    )
}

export default Collective
