const API_DATA_LIMIT = 10;
const NFT_DATA_LIMIT = 12;


const PAGES = {
  user: "user",
  order: 'order',
  thali: 'thali',
  collectible: 'collectible',
  collectibleType: 'collectibleType'
};

const ROLES = {
  admin: "admin",
  subAdmin: "subAdmin",
};

const FORMIK_REGEX = {
  PASSWORD_REGEX:
    /^.*(?=.{6})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  WHOLE_DECIMALNUMBERS_REGEX: /^[1-9]\d{0,9}(\.\d{1,2})?%?$/,
  NATURAL_NUMBERS_REGEX: /^[1-9][0-9]{0,9}$/,
  ROYALITY_REGEX: /^(?:[1-9]|[1-9][0-9]|100)$/,
  EMAIL_REGEX:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
const PLACEHOLDER_TEXT = {
  Nft: "Search by NFTs name",
  default: "Search by email, name and phone no. ",
  title: "Search by Title",
  email: "Search by Email",
  search: "Search by email, name and phone no.",
  searchorder: "Search by email and name",
  name: "Search by Name",
};

export {
  API_DATA_LIMIT,
  PAGES,
  ROLES,
  FORMIK_REGEX,
  PLACEHOLDER_TEXT,
  NFT_DATA_LIMIT,
};


export const S3_BUCKET = {
  URL: " https://dvf7opio6knc7.cloudfront.net/satyug",
  IMAGESLOGO: " https://dvf7opio6knc7.cloudfront.net/digital-logo1.png",
  IMAGES: "https://dvf7opio6knc7.cloudfront.net/satyugImages",
  VIDEOS: "https://dvf7opio6knc7.cloudfront.net/satyugvideos",
  SHIRT: " https://dvf7opio6knc7.cloudfront.net/products/Tshirt.png",
  CAP: " https://dvf7opio6knc7.cloudfront.net/products/cap.png",
  BEFORE_GAME: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/BeforeGam.mp4",
  CINEMATIC_GAME: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/Construction.mp4",
  DAKSHINA_GAME: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/Bhojnalya_stage.mp4",
  SATHAPNA_GAME: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/Murti_stage.mp4",
  NIRMAAN_GAME: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/bagicha-stage.mp4",
  ABOUT_US_TEXT: "https://dvf7opio6knc7.cloudfront.net/satyugImages/about-us.png",
  CLICK_TO_ENTER_TEXT: "https://dvf7opio6knc7.cloudfront.net/satyugImages/click-to-enter.png",
  SATYUG_VIDEO_POSTER: "https://dvf7opio6knc7.cloudfront.net/satyugImages/satyug-video-poster.webp",
  SHARE_PAGE_VIDEO: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/BahumulyaWEBM.webm",
  USER_NOT_EXIST_VIDEO: "https://dvf7opio6knc7.cloudfront.net/video_v2/02.webm",
  BAGEECHA: "https://dvf7opio6knc7.cloudfront.net/satyugImages/bageecha.webp",
  MURTI_STHAPANA: "https://dvf7opio6knc7.cloudfront.net/satyugImages/murthi-sathapna.webp",
  BHOJNALYA: "https://dvf7opio6knc7.cloudfront.net/satyugImages/bhojnalaya.webp",
  MANDIR: "https://dvf7opio6knc7.cloudfront.net/satyugImages/card-image.webp",
  SHARE_IMAGE: "https://dvf7opio6knc7.cloudfront.net/satyugImages/share-image.webp",
  ABOUT_US_HIGH: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/about-us.mp4",
  ABOUT_US_LOW: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/about-us-low.mp4",
  ABOUT_US_480: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/about-us-480.mp4",
  ABOUT_US_720: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/about-us-720.mp4",
  SATYUG_VIDEO: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/Satyug.mp4",
  SATYUG_VIDEO_LOW: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/satyug-low.mp4",
  DHYANA_VIDEO: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/dhyana.mp4",
  DHYANA_VIDEO_LOW: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/dhyana-low.mp4",
  YOGA_VIDEO: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/yoga.mp4",
  YOGA_VIDEO_LOW: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/yoga-low.mp4",
  SHARE_BACK: "https://dvf7opio6knc7.cloudfront.net/satyugvideos/sharevideo.mp4",
  ABOUT_US_POSTER: "https://dvf7opio6knc7.cloudfront.net/satyugImages/about-us-poster.png",
  LOGO_HD: " https://dvf7opio6knc7.cloudfront.net/satyugImages/logo-hd.webp",
  BEFORE_GAME_720: " https://dvf7opio6knc7.cloudfront.net/satyugvideos/before-game-720.mp4",
  BEFORE_GAME_480: " https://dvf7opio6knc7.cloudfront.net/satyugvideos/before-game-480.mp4",
  BEFORE_GAME_360: " https://dvf7opio6knc7.cloudfront.net/satyugvideos/before-game-360.mp4",
  SHARE_360: " https://dvf7opio6knc7.cloudfront.net/satyugvideos/share-360.mp4",
  SHARE_480: " https://dvf7opio6knc7.cloudfront.net/satyugvideos/share-480.mp4",
  OLD_LOGO: " https://dvf7opio6knc7.cloudfront.net/satyugImages/satyug-old-logo.webp",
  BADHAI: " https://dvf7opio6knc7.cloudfront.net/satyugvideos/Badhai (1).webm",
  DIGITAL_LOGO: " https://dvf7opio6knc7.cloudfront.net/satyugImages/digital-logo.png",
  PRABHU_SHINGAR: "https://dvf7opio6knc7.cloudfront.net/video_v2/webm/prabhu_shingaar.webm",
  BAGICHA_NIRMAN: "https://dvf7opio6knc7.cloudfront.net/video_v2/webm/bagicha_nirmaan.webm",
  BHOJNALYA_YOGDAN: "https://dvf7opio6knc7.cloudfront.net/video_v2/webm/Aap_ka_yogdaan.webm",
  WELCOME_FLAG: " https://dvf7opio6knc7.cloudfront.net/satyugImages/welcome-flag.png",
  WELCOME_LEFT_ARROW: " https://dvf7opio6knc7.cloudfront.net/satyugImages/welcome-arrow-left.png",
  WELCOME_RIGHT_ARROW: " https://dvf7opio6knc7.cloudfront.net/satyugImages/welcome-flag-right.png",
  UP_BOW: " https://dvf7opio6knc7.cloudfront.net/satyugImages/up-bow.png",
  SILVER_BOW: " https://dvf7opio6knc7.cloudfront.net/satyugImages/silver-bow.png",
  CERTIFICATE: " https://dvf7opio6knc7.cloudfront.net/satyugImages/sample-certificate",
  EASY_MODE: " https://dvf7opio6knc7.cloudfront.net/satyugImages/easy-mode.png",
  HARD_MODE: " https://dvf7opio6knc7.cloudfront.net/satyugImages/hard-mode.png",
  AFTER_END_BUY: " https://dvf7opio6knc7.cloudfront.net/video_v2/E-commerce%20web%20site%20last%20dialogue.webm",
  BEFORE_END_BUY: "",
  BEFORE_SHARE_END: "",
};