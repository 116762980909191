import React, { memo, useEffect, useState } from "react";
import "./contact.scss";
import "../UserManagement/UserManagement.scss";
import { Col, Container, Row } from "react-bootstrap";
import CommonTable from "../../../common/ui/CommonTable/CommonTable";
import { apiCallGet, } from "../../../../axios/axios";
import {
  Address,
  checkUndefiendValue,
  getEmailDisplay,
  truncateName,
} from "../../../../utils/utils";
import {
  API_DATA_LIMIT,
  PAGES,
  PLACEHOLDER_TEXT,
} from "../../../../utils/constants";
import PaginationWrapper from "../../../common/ui/PaginationWrapper/PaginationWrapper";
import moment from "moment";

const Contact = () => {
  const [contactData, setContactData] = useState({})
  const [offset, setOffset] = useState(1);

  const getContactList = async () => {
    let res = await apiCallGet(
      `/api/v1/satyug/contact-us/${API_DATA_LIMIT}/${offset}`,
      {},
      false,
      true
    );
    if (!res?.error) {
      setContactData(res?.data);
    }
  };

  useEffect(() => {
    getContactList()
  }, [offset])

  return (
    <>
      <section className="contact_page">
        <Container fluid>
          <Row>
            <Col>
              <CommonTable serialised={true} className="user_management_table">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Date</th>
                    <th>Question</th>
                  </tr>
                </thead>
                <tbody>
                  {contactData?.count > 0 ? (
                    contactData?.rows?.map((item, index) => (
                      <tr key={index}>
                        <td>{offset * 10 - 10 + index + 1}</td>
                        <td>
                          {checkUndefiendValue(
                            truncateName(
                              item?.firstName,
                              item?.lastName
                            ),
                            "--"
                          )}
                        </td>
                        <td>
                          {checkUndefiendValue(
                            getEmailDisplay(item?.email),
                            "--"
                          )}
                        </td>
                        <td>{moment(item?.createdAt).format('DD-MM-YYYY') || "--"}</td>
                        <td>
                          <span className="contact_ques">{checkUndefiendValue(item?.message, '--')}</span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="no_data_found">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </CommonTable>
              {contactData?.count > 0 && (
                <div className="page_nation">
                  <PaginationWrapper
                    limit={API_DATA_LIMIT}
                    page={offset}
                    count={contactData?.count}
                    onChange={(e) => setOffset(e)}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default memo(Contact);
