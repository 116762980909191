import React from "react";
import Modal from "react-bootstrap/Modal";
import { CrossIcon } from "../../../../../assets/images/svg/SvgIcon";
import "./CommonModal.scss";

const CommonModal = ({
  show,
  onHide,
  className,
  size,
  heading,
  children,
  closeButton,
  scrollable,
  onClose,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size}
      centered
      scrollable={scrollable}
      className={`common_modal ${className || ""}`}
    >
      {heading && (
        <Modal.Header>
          <Modal.Title>{heading}</Modal.Title>
          <button
            className="close_btn"
            onClick={() => {
              onHide();
              onClose && onClose();
            }}
          >
            <CrossIcon />
          </button>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default CommonModal;
