import React, { memo, useEffect, useState } from "react";
import "./CollectibleTypeModal.scss";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import CommonModal from "../CommonModal/CommonModal";
import FormikControls from "../../Formik/FormikControls";
import CommonBtn from "../../CommonBtn/CommonBtn";

import {
  apiCallGet,
  apiCallPatch,
  apiCallPost,
} from "../../../../../axios/axios";

const SubCollectibleModal = ({ show, onHide, onSuccess, isUpdate, item }) => {

  const [selectedType, setSelectedType] = useState('');
  const [imageSrc, setImageSrc] = useState(null);

  const title = isUpdate ? "Update" : "Add";

  // states
  const [blogImg, setBlogImg] = useState("");
  const [blogData, setBlogData] = useState("");

  const initialValues = {
    // category: blogDatas?.title || "",
    description: blogData?.description || "",
    subCategory:blogData?.name  || ""
  };
  const validationSchema = Yup.object({
    // category: Yup.string()
    //   .required("Please enter category")
    //   .min("2", "Category must be between 2-40 characters")
    //   .max("40", "Category must be between 2-40 characters"),
      subCategory: Yup.string()
      .required("Please enter Sub-category")
      .min("2", "Sub-category must be between 2-40 characters")
      .max("40", "Sub-category must be between 2-40 characters"),
      description: Yup.string()
      .required("Please enter description")
      .min("2", "description must be between 2-100 characters")
      .max("100", "description must be between 2-100 characters"),
  });

  // upload image
  async function handleImageUpload(e, formik) {
    try {
      const file = e.target.files[0];
      if (file.size <= 5 * 1024 * 1024) {
        let data = new FormData();
        data.append("type", "products");
        data.append("upload", file);

        const result = await apiCallPost(
          "/api/v1/satyug/file/upload",
          data,
          {},
          true,
          true
        );

        if (result && !result.error) {
          let img = result?.data?.image;
          setBlogImg(img);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  // add blog
  const addBlog = async (values) => {
    let payload = {
    //   image: blogImg,
    //   categoryName: values.category,
      categoryId: item?.id,
      subCategory:values.subCategory,
      description: values.description,
    };
    let res = await apiCallPost(`/api/v1/satyug/collectableType`, payload, {}, true, true);
    if (!res?.error) {
      onHide();
      onSuccess();
    }
  };
  // update blog
  const updateBlog = async (values) => {
    let payload = {
    //   image: blogImg || blogDatas?.image,
    //   categoryName: values.category,
    categoryId: item?.id,
    name:values.subCategory,
      description: values.description,
    };
    let res = await apiCallPatch(`api/v1/satyug/collectableType/${show}`, payload, {}, true, true);
    if (!res?.error) {
      onHide();
      onSuccess();
    }else{
     
    }
  };

  const onSubmit = (values) => {
    if (isUpdate) {
      updateBlog(values);
    } else {
    //   if (blogImg) {
        addBlog(values);
    //   } else {
    //     toasts.error("Please upload image");
    //   }
    }
  };

  // get blog by id
  const getBlogById = async () => {
    const payload = {
      id : show
    }
    let res = await apiCallPost(`/api/v1/satyug/collectableType/category`,payload, {}, false, true);
    if (!res?.error) {
      setBlogData(res?.data);
    }
  };

  useEffect(() => {
    isUpdate && getBlogById();
  }, []);

  // const onBlogSuccessCallback = () => {
  //   if (selectedType === "type cap") {
  //     // setSelectedSize("");
  //     setSelectedType("");
  //   }
  //   // getproductList();
  // };

//   const handleTypeChange = (e) => {
//     setSelectedType(e.target.value);
//   };

//   useEffect(() => {
//     if (blogImg) {
//       // If blogImg is available, set the imageSrc to it
//       setImageSrc(blogImg);
//     } else {
//       // If blogImg is not available, set the imageSrc to the first blogData image (if available)
//       setImageSrc(blogDatas?.image || null);
//     }
//   }, [blogImg, blogData]);
console.log('item111',item);

  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        className="product_modal no_btn"
        heading={`${title} Collectible`}
      >
        <div className="product_modal_content">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                {/* <Row>
                  {imageSrc ?
                    <Col>
                      <div className="updateBlogImage">
                        <img src={blogImg || blogDatas?.image}></img>
                      </div>
                    </Col>
                    : ""}
                  <Col>
                    <div className="product_modal_content_item">
                      <UploadIconNew />
                      <h3>Upload Here</h3>
                      <FormikControls
                        className="upload_sec"
                        // label="Add Image"
                        control="input"
                        type="file"
                        // onBlur={formik.handleBlur}
                        formik={formik}
                        name="blogImg"
                        accept="image/png, image/jpeg"
                        onChange={(e) => handleImageUpload(e, formik)}
                      />
                    </div>
                  </Col>
                </Row> */}

                {/* <FormikControls
                  label="Category Name"
                  placeholder="Enter Category"
                  control="input"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.category}
                  formik={formik}
                  name="category"
                /> */}
                 <FormikControls
                  label="Sub-category"
                  placeholder="Enter Sub-category"
                  control="input"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.subCategory}
                  formik={formik}
                  name="subCategory"
                />
                <FormikControls
                  label="Description"
                  placeholder="Enter description"
                  control="input"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  formik={formik}
                  name="description"
                />
                <div className="btn_sec text-center d-sm-flex justify-content-between flex-row-reverse">
                  <CommonBtn
                    className="mb-sm-0 mb-3"
                    role="btn"
                    title={title}
                    disabled={!formik.isValid}
                  />
                  <CommonBtn
                    className="disable-btn"
                    role="btn"
                    title="Cancel"
                    type="button"
                    onClick={onHide}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </CommonModal>
    </>
  );
};

export default memo(SubCollectibleModal);
