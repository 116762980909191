// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: "",
  userData: "",
  collectibleType : "",
  role: "admin",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
      setUserData: (state, action) => {
        state.userData = {
          ...state.userData,
          ...action.payload,
        };
      },
      setCollectibleTypes : (state , action) => {
        state.collectibleType ={
          ...state.collectibleType,
          ...action.payload
        }
      }
  },
});

export const { setAuth, setUserData,setCollectibleTypes } = userSlice.actions;

export default userSlice.reducer;
