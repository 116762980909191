import { Field, ErrorMessage } from "formik";
import React from "react";
import Form from "react-bootstrap/Form";
import TextError from "./TextError/TextError";

const Radio = (props) => {
  const { disabled, onclick, name, label, options, ...rest } = props;
  return (
    <Form.Group className="common_radio" style={{ display: 'flex', gap: "10px", alignItems: 'center' }} controlId={name}>
      <Form.Label className="mb-0">{label}</Form.Label>
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <div key={option.key}>
                <input
                  type="radio"
                  id={option.value}
                  {...field}
                  value={option.value}
                  checked={field.value === option.value}
                  onChange={field.onChange}
                  onClick={onclick}
                  disabled={disabled}
                />
                <label htmlFor={option.value}>{option.key}</label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </Form.Group>
  );
};

export default Radio;
