import React, { memo, useEffect, useState } from "react";
import "./UpdateBlogModal.scss";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import CommonModal from "../CommonModal/CommonModal";
import FormikControls from "../../Formik/FormikControls";
import { UploadIconNew } from "../../../../../assets/images/svg/SvgIcon";
import CommonBtn from "../../CommonBtn/CommonBtn";

import {
  apiCallGet,
  apiCallPatch,
  apiCallPost,
} from "../../../../../axios/axios";
import { toasts } from "../../Toast/Toast";
import { Col, Row } from "react-bootstrap";


const ProductModal = ({ show, onHide, onSuccess, isUpdate, combinedProducts }) => {

  const [selectedType, setSelectedType] = useState('');
  const [imageSrc, setImageSrc] = useState(null);

  const combinedProduct = combinedProducts['t-shirt'];
  const capProduct = combinedProducts['cap'];
  const title = isUpdate ? "Update" : "Add";

  const radioOptions = [
    {
      key: "S",
      value: "S",
    },
    {
      key: "M",
      value: "M",
    },
    {
      key: "L",
      value: "L",
    },
    {
      key: "XL",
      value: "XL",
    },
    {
      key: "XXL",
      value: "XXL",
    },
  ];

  const typeOptions = [
    {
      key: "Cap",
      value: "cap",
    },
    {
      key: "T-Shirt",
      value: "t-shirt",
    },
  ];

  const capTypeExists = capProduct && capProduct.length > 0 && capProduct.some(product => product.type === 'cap');



  function hideUnavailableOptions(combinedProduct, radioOptions) {
    if (!Array.isArray(combinedProduct)) {
      return radioOptions;
    }

    const availableSizes = combinedProduct.reduce((sizes, product) => {
      if (product.quantity > 0) {
        sizes.add(product.size);
      }
      return sizes;
    }, new Set());

    return radioOptions.filter((option) => !availableSizes.has(option.key));
  }

  const filteredRadioOptions = isUpdate ? radioOptions : hideUnavailableOptions(combinedProduct, radioOptions);

  // states
  const [blogImg, setBlogImg] = useState("");
  const [blogData, setBlogData] = useState("");

  const blogDatas = blogData[0]

  const initialValues = {
    title: blogDatas?.title || "",
    description: blogDatas?.description || "",
    type: blogDatas?.type || "",
    size: blogDatas?.size || "",
    quantity: blogDatas?.quantity || "",
    price: blogDatas?.price || "",
  };

  const getSizeValidation = (selectedType) => {
    return selectedType !== 'cap' ? Yup.string().required("Please select size") : Yup.string();
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Please enter title")
      .min("2", "Name must be between 2-40 characters")
      .max("40", "Name must be between 2-40 characters"),
    type: Yup.string()
      .required("Please select type"),

    size: getSizeValidation(selectedType),
    description: Yup.string()
      .min("150", "Description must be between 150-500 characters")
      .max("500", "Description must be between 150-500 characters")
      .required("Please enter description"),
      price: Yup.number() 
      .required("Please enter price")
      .min(1, "Price should not be less than 1")
      .integer()
      .max(100000, "Price should not be more than 1 lakh"),
    quantity: Yup.number() 
      .required('Please enter quantity')
      .min(1, "Quantity should not be less than 1")
      .integer()
      .max(100000, "Quantity should not be more than 1 lakh"),
  });

  // upload image
  async function handleImageUpload(e, formik) {
    try {
      const file = e.target.files[0];
      if (file.size <= 5 * 1024 * 1024) {
        let data = new FormData();
        data.append("type", "products");
        data.append("upload", file);

        const result = await apiCallPost(
          "/api/v1/satyug/file/upload",
          data,
          {},
          true,
          true
        );

        if (result && !result.error) {
          let img = result?.data?.image;
          setBlogImg(img);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // add blog
  const addBlog = async (values) => {
    const sizes = selectedType === 'cap' ? 'M' : values.size;
    let payload = {
      image: blogImg,
      title: values.title,
      type: values.type,
      description: values.description,
      size: sizes,
      price: values.price,
      quantity: values.quantity
    };
    let res = await apiCallPost(`/api/v1/satyug/product`, payload, {}, true, true);
    if (!res?.error) {
      onHide();
      onSuccess();
    }
  };
  // update blog
  const updateBlog = async (values) => {
    let payload = {
      // id: show?.id,
      image: blogImg || blogDatas?.image,
      title: values.title,
      // type: values.type,
      description: values.description,
      size: values.size,
      price: values.price,
      quantity: values.quantity

    };
    let res = await apiCallPatch(`api/v1/satyug/product/${show?.id}`, payload, {}, true, true);
    if (!res?.error) {
      onHide();
      onSuccess();
    }
  };

  const onSubmit = (values) => {
    if (isUpdate) {
      updateBlog(values);
    } else {
      if (blogImg) {
        addBlog(values);
      } else {
        toasts.error("Please upload image");
      }
    }
  };

  // get blog by id
  const getBlogById = async () => {
    let res = await apiCallGet(`api/v1/satyug/product/${show?.id}`, {}, false, true);
    if (!res?.error) {
      setBlogData(res?.data);
    }
  };

  useEffect(() => {
    isUpdate && getBlogById();
  }, []);

  // const onBlogSuccessCallback = () => {
  //   if (selectedType === "type cap") {
  //     // setSelectedSize("");
  //     setSelectedType("");
  //   }
  //   // getproductList();
  // };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  useEffect(() => {
    if (blogImg) {
      // If blogImg is available, set the imageSrc to it
      setImageSrc(blogImg);
    } else {
      // If blogImg is not available, set the imageSrc to the first blogData image (if available)
      setImageSrc(blogDatas?.image || null);
    }
  }, [blogImg, blogData]);

  const tShirtTypeExists = combinedProducts['t-shirt'] && combinedProducts['t-shirt'].length > 0 &&
    combinedProducts['t-shirt'].some(product => product.type === 't-shirt');

  const modifiedTypeOptions = typeOptions.filter(option => {
    if (option.value === 'cap') {
      return !capTypeExists;
    }
    if (filteredRadioOptions >= 0) {
      if (option.value === 't-shirt') {
        return !tShirtTypeExists;
      }
    }
    return true;
  });



  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        className="product_modal no_btn"
        heading={`${title} Product`}
      >
        <div className="product_modal_content">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <Row>
                  {imageSrc ?
                    <Col>
                      <div className="updateBlogImage">
                        <img src={blogImg || blogDatas?.image}></img>
                      </div>
                    </Col>
                    : ""}
                  <Col>
                    <div className="product_modal_content_item">
                      <UploadIconNew />
                      <h3>Upload Here</h3>
                      <FormikControls
                        className="upload_sec"
                        // label="Add Image"
                        control="input"
                        type="file"
                        // onBlur={formik.handleBlur}
                        formik={formik}
                        name="blogImg"
                        accept="image/png, image/jpeg"
                        onChange={(e) => handleImageUpload(e, formik)}
                      />
                    </div>
                  </Col>
                </Row>

                <FormikControls
                  label="Title"
                  placeholder="Enter title"
                  control="input"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  formik={formik}
                  name="title"
                />
                <FormikControls
                  label="Description"
                  placeholder="Enter description"
                  control="input"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  formik={formik}
                  name="description"
                />
                <FormikControls
                  label="Type"
                  control="radio"
                  placeholder={`Select type`}
                  options={isUpdate ? [{ key: blogDatas?.type, value: blogDatas?.type }] : modifiedTypeOptions}
                  value={formik.values.type}
                  formik={formik}
                  name="type"
                  onclick={handleTypeChange}
                  disabled={isUpdate}
                />
                <br />
                {
                  blogDatas?.type === "cap" ? "" :
                    selectedType === 'cap' ? "" :
                      <FormikControls
                        label="Size"
                        control="radio"
                        placeholder={`Select size`}
                        options={isUpdate ? [{ key: blogDatas?.size, value: blogDatas?.size }] : filteredRadioOptions}
                        value={formik.values.size}
                        formik={formik}
                        name="size"
                        disabled={isUpdate}
                      />
                }
                <br />
                <FormikControls
                  label="Quantity"
                  placeholder="Enter quantity"
                  control="input"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.quantity}
                  formik={formik}
                  name="quantity"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", ".", "*", "/"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                />

                <FormikControls
                  label="Price"
                  placeholder="Enter price"
                  control="input"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.price}
                  formik={formik}
                  name="price"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", ".", "*", "/"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                />
                <div className="btn_sec text-center d-sm-flex justify-content-between flex-row-reverse">
                  <CommonBtn
                    className="mb-sm-0 mb-3"
                    role="btn"
                    title={title}
                    disabled={!formik.isValid}
                  />
                  <CommonBtn
                    className="disable-btn"
                    role="btn"
                    title="Cancel"
                    type="button"
                    onClick={onHide}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </CommonModal>
    </>
  );
};

export default memo(ProductModal);
