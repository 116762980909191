import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import satyugLogo from "../../../../assets/images/img/logo.png";
import { CommonBtn, CommonHeading } from "../../ui";
import "./AuthLayout.scss";
import { CloseIcon } from "../../../../assets/images/svg/SvgIcon";
import { S3_BUCKET } from "../../../../utils/constants";


const AuthLayout = ({
  children,
  mainImg,
  alt,
  title,
  text,
  className,
  isOtherMethodHide,
  bottomText,
  isButton
}) => {
  const ram = ' https://dvf7opio6knc7.cloudfront.net/webiste/s%201.png'
  return (
    <main className={`auth-layout ${className}`}>
      {isButton === true ? null :
        <CommonBtn
          role="link"
          to="/"
          iconBefore={'Cancel'}
          className="auth-layout__closeBtn icon border-0 "
        />
      }
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="auth-layout__left">
              <span className="auth-layout__logo d-block text-center">
                <img src={S3_BUCKET.DIGITAL_LOGO} alt="satyug-logo" />
              </span>
              <div className="auth-layout__left__img">
                <img src={ram} alt={alt} className="d-none d-md-block" />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="auth-layout__right d-flex align-items-center ">
              <div className="auth-card">
                <CommonHeading title={title} text={text} />
                {children}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default AuthLayout;
