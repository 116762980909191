import React from "react";
import { useSelector } from "react-redux";
import LoaderUi from "./component/common/ui/Loader/LoaderUi";
import Routes from "./routes/Routes";

function App() {
  const { isLoading } = useSelector((state) => state.loader);
  return (
    <>
      {isLoading && <LoaderUi active={isLoading} />}
      <Routes />
    </>
  );
}

export default App;
