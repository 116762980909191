import React, { memo, useEffect, useState } from "react";
import "./CollectibleType.scss";
import "../UserManagement/UserManagement.scss";
import { Col, Container, Row } from "react-bootstrap";
import CommonTable from "../../../common/ui/CommonTable/CommonTable";
import {
    checkUndefiendValue,
} from "../../../../utils/utils";
import {
    API_DATA_LIMIT,
    PAGES,
    PLACEHOLDER_TEXT,
} from "../../../../utils/constants";
import PaginationWrapper from "../../../common/ui/PaginationWrapper/PaginationWrapper";
import Filter from "../../../common/Filter/Filter";
import SubCollectibleModal from "../../../common/ui/Modals/CollectibleTypeModal/SubCollectibleModal";
import { useDispatch, useSelector } from "react-redux";
import { apiCallDelete, apiCallGet, apiCallPost } from "../../../../axios/axios";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../common/ui/Modals/DeleteModal/DeleteModal";
import { DeleteIcon, EditIcon } from "../../../../assets/images/svg/SvgIcon";

const SubColletibleType = () => {
    const [showAdd, setShowAdd] = useState(false);
    const [offset, setOffset] = useState(1);
    const [itemToUpdate, setItemToUpdate] = useState("");
    const [itemToDelete, setItemToDelete] = useState("");
    const [data, setData] = useState({})
    const collectibleType = useSelector((state) => state.user.collectibleType);
    const naviagete = useNavigate()


    const handleHide = () => {
        setItemToUpdate(false);
    };
    const getBlogById = async () => {
        const payload = {
            parentId: collectibleType?.id
        }
        let res = await apiCallPost(`/api/v1/satyug/collectableType/category`, payload, {}, false, true);
        if (!res?.error) {
            setData(res?.data);
        }
    };
    useEffect(() => {
        getBlogById()
    }, [])
    const onBlogSuccessCallback = () => {
        // naviagete("/admin/collectibleType")
        getBlogById()
    };
    const deleteCollectible = async () => {
        let res = await apiCallDelete(
            `/api/v1/satyug/collectableType/${itemToDelete}`,
            {},
            {},
            true,
            true
        );
        if (!res?.error) {
            setItemToDelete("")
            getBlogById()
        }
    };
    const deleteHandler = (item) => setItemToDelete(item);

    const submitCallback = () => {
        deleteCollectible();
    };



    return (
        <>
            <section className="contact_page">
                {data?.count === 0 ?
                <Filter
                    isButton
                    onClick={() => { setShowAdd(true) }}
                    title={<p>Add Sub-Category</p>}
                    page={PAGES.collectibleType}
                    placeholdertext={PLACEHOLDER_TEXT.title}
                />
:''}
                <Container fluid>
                    <Row>
                        <Col>
                            <CommonTable serialised={true} className="user_management_table">
                                <thead>
                                    <tr>
                                        <th>Sr No.</th>
                                        <th>Sub-Category</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.length > 0 ? (
                                        data?.map((subitem, index) => (
                                            <tr key={index}>
                                                <td>{offset * 10 - 10 + index + 1}</td>
                                                <td>
                                                    {checkUndefiendValue(
                                                        subitem?.name, "--"
                                                    )}
                                                </td>
                                                <td>
                                                    {checkUndefiendValue(subitem?.description, '--')}
                                                </td>
                                                <td>
                                                    <div className="action_box">
                                                        <button
                                                            onClick={() => setItemToUpdate(subitem?.id)}
                                                            className="action_btn"
                                                        >
                                                            <EditIcon />
                                                        </button>
                                                        <button
                                                            className="action_btn"
                                                            onClick={() => deleteHandler(subitem?.id)}
                                                        >
                                                            <DeleteIcon />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={4} className="no_data_found">
                                                No data found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>

                            </CommonTable>
                            {data?.length > 0 && (
                                <div className="page_nation">
                                    <PaginationWrapper
                                        limit={API_DATA_LIMIT}
                                        page={offset}
                                        count={data?.length}
                                        onChange={(e) => setOffset(e)}
                                    />
                                </div>
                            )}
                            {showAdd && (
                                <SubCollectibleModal
                                    item={collectibleType}
                                    show={showAdd}
                                    onHide={() => setShowAdd(false)}
                                    onSuccess={onBlogSuccessCallback}
                                />
                            )}
                            {itemToUpdate && (
                                <SubCollectibleModal
                                    show={itemToUpdate}
                                    onHide={() => handleHide()}
                                    onSuccess={onBlogSuccessCallback}
                                    isUpdate={true}
                                />
                            )}
                            <DeleteModal
                                title={"Are you sure you want to delete this Sub-Category"}
                                show={itemToDelete}
                                onHide={() => setItemToDelete("")}
                                onSubmit={submitCallback}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default memo(SubColletibleType);
