import React, { useCallback, useEffect } from "react";
import { Form, Formik } from "formik";
// import * as Yup from "yup";
import "./Filter.scss";
import { CommonBtn, FormikControls } from "../ui";
import Customdropdown from "../ui/Customdropdown/Customdropdown";
import { useDispatch } from "react-redux";
import { setFilters } from "../../../redux/filters";
import { debouncing } from "../../../utils/utils";
import { useState } from "react";
import moment from "moment";
import Select from "react-select";
import { Col, Row } from "react-bootstrap";
// import { city } from "../../pages/Private/CreateNFT/nftdata";

const Filter = ({
  isEmail,
  to,
  selectors,
  onClick,
  title,
  isDropdown,
  isStartdate,
  isToDate,
  isButton,
  isSearch,
  page,
  placeholdertext,
  Isdisabled,
  background,
  isSelect,
}) => {
  const initialValues = {
    mail: "",
    password: "",
    startDate: "",
    endDate: "",
    toggle: false,
    search: "",
    status: "All",
  };
  // hooks
  const dispatch = useDispatch();
  // states
  const [filtered, setFiltered] = useState("");

  // const validationSchema = Yup.object({
  //   mail: Yup.string(),
  //   password: Yup.string(),
  //   check: Yup.string(),
  //   startDate: Yup.string(),
  //   endDate: Yup.string(),
  // });

  //   const validationSchema = Yup.object({
  //     mail: Yup.string().required("You have entered a wrong email"),
  //     password: Yup.string().required("You have entered a wrong password"),
  //     check: Yup.string().required("Please click on checkbox before login"),
  //     startDate: Yup.string().required("Please enter a correct start date"),
  //     endDate: Yup.string().required("Please enter a correct end date"),
  //   });
  // const onSubmit = (values) => {
  //   console.log("overall-target ====>", values);
  //   // dispatch(setFilters(values));
  // };
  const options = [
    {
      to: "#/action-1",
      value: "VIRTUAL",
      label: "Virtual",
    },
    {
      to: "#/action-2",
      value: "PHYSICAL",
      label: "Physcial",
    },
  ];

  const debouncedDispatch = useCallback(
    debouncing((values) => dispatch(setFilters({ key: page, value: values }))),
    []
  );

  const inputOnChangeHandler = (e, formik) => {
    const { name, value } = e.target;
    setFiltered({ ...filtered, [name]: value });
  };

  const selectClickCallback = (selectedItem, name) => {
    if (name === "country") {
      setFiltered({ ...filtered, [name]: selectedItem.name, city: "" });
    } else {
      setFiltered({
        ...filtered,
        [name]: page == "CreateNFT" ? selectedItem : selectedItem?.name,
      });
    }
  };
  useEffect(() => {
    filtered && debouncedDispatch(filtered);
    return () => {
      debouncedDispatch({});
    };
  }, [filtered]);

  return (
    <div className="filter_form">
      <Formik
        initialValues={initialValues}
      // validationSchema={validationSchema}
      // onSubmit={onSubmit}
      >
        {(formik, handleChange) => (
          <Form className="admin_form">
            <Row>
              {isDropdown && (
                <Col lg={4} md={6}>
                  <div className="filter_dropdown">
                    <p>Sort By</p>
                    <Customdropdown
                      title="Buy"
                      className="buy_dropdown"
                      dropdownItems={options}
                    />
                  </div>
                </Col>
              )}

              {isEmail && (
                <Col lg={4} md={6}>
                  <FormikControls
                    label="Email"
                    placeholder="Search by Email"
                    control="input"
                    type="email"
                    onChange={(e) => inputOnChangeHandler(e, formik)}
                    onBlur={formik.handleBlur}
                    value={filtered?.mail}
                    formik={formik}
                    name="mail"
                  />
                </Col>
              )}
              {isSearch && (
                <Col lg={4} md={6}>
                  <FormikControls
                    label="Search"
                    placeholder={placeholdertext}
                    control="input"
                    type="search"
                    onChange={(e) => inputOnChangeHandler(e, formik)}
                    onBlur={formik.handleBlur}
                    value={filtered?.search}
                    formik={formik}
                    name="search"
                    className="form_search form-control"
                  />
                </Col>
              )}
              {isSelect && (
                <Col lg={4} md={6}>
                  <Select
                    options={options}
                    defaultValue="Select City"
                    onChange={(name) => selectClickCallback(name, "city")}
                    placeholder={placeholdertext}
                  />
                </Col>
              )}
              {isStartdate && (

                <Col lg={4} md={6}>
                  <FormikControls
                    label="From Date"
                    placeholder="Enter your email"
                    className="form_date form-control"
                    control="input"
                    type="date"
                    onChange={(e) => inputOnChangeHandler(e, formik)}
                    onBlur={formik.handleBlur}
                    value={filtered?.startDate}
                    formik={formik}
                    max={moment().format("YYYY-MM-DD")}
                    name="startDate"
                  />
                </Col>
              )}
              {isToDate && (

                <Col lg={4} md={6}>
                  <FormikControls
                    label="To Date"
                    placeholder="Enter your email"
                    className="form_date form-control"
                    control="input"
                    type="date"
                    onChange={(e) => inputOnChangeHandler(e, formik)}
                    onBlur={formik.handleBlur}
                    value={filtered?.endDate}
                    formik={formik}
                    max={moment().format("YYYY-MM-DD")}
                    name="endDate"
                  />
                </Col>
              )}

              {selectors?.length > 0 &&
                selectors?.map((item) => (

                  <Col lg={4} md={6}>
                    <div key={item.name} className="filter_select">
                      <FormikControls
                        label={item.label}
                        defaultText={item.defaultOption}
                        optionsList={item.options}
                        onClickCallback={selectClickCallback}
                        control="select"
                        type="text"
                        formik={formik}
                        name={item.name}
                        disabled={item.name === "city" ? Isdisabled : ""}
                        background={
                          item.name === "city" && background ? "#f5f5f547" : ""
                        }
                      />
                    </div>
                  </Col>
                ))}
            </Row>
          </Form>
        )}
      </Formik>
      {isButton && (
        <div className="d-flex justify-content-end mb-4">
          <CommonBtn
            title={title}
            role="link"
            to={to}
            className="exportBtn"
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
};

export default Filter;
