import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PrimaryLayouts } from "../component/common/layouts";
import {
 
  Dashboard,
  Login,
  UserManagement,
  ProfileSetting,
  ErrorPage,
  Quiz,
  Donation,
  QuizLeaderBoard,
  Order
} from "../component/pages";
import NoAuthGaurd from "./NoAuthGaurd";
import AuthGuard from "./AuthGuard";
import RoleGuard from "./RoleGuard";
import { ROLES } from "../utils/constants";
import ForgotPassword from "../component/pages/public/AuthPages/ForgotPassword";
import Collective from "../component/pages/Private/Collective/Collective";
import Product from "../component/pages/Private/Product/Product";
import LoaderUi from "../component/common/ui/Loader/LoaderUi";
import Contact from "../component/pages/Private/Contact/Contact";
import CollectibleType from "../component/pages/Private/CollectibleType/CollectibleType";
import SubColletibleType from "../component/pages/Private/CollectibleType/SubColletibleType";
import Thali from "../component/pages/Private/Thali/Thali";
// import CreateNFTItem from "../component/pages/Private/CreateNFT/CreateNFTItem";
// import BannerManagement from "../component/pages/Private/BannerManagement/BannerManagement";

const router = createBrowserRouter([
  // public pages
  {
    path: "/",
    element: (
      <NoAuthGaurd>
        <Login />
      </NoAuthGaurd>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgot-password",
    element: (
      <NoAuthGaurd>
        <ForgotPassword />
      </NoAuthGaurd>
    ),
  },

  // {
  //   path: "/otp-verification",
  //   element: (
  //     <NoAuthGaurd>
  //       <OtpVerification />
  //     </NoAuthGaurd>
  //   ),
  // },
  // {
  //   path: "/change-password",
  //   element: (
  //     <NoAuthGaurd>
  //       <ChangePassword />
  //     </NoAuthGaurd>
  //   ),
  // },
  {
    path: "/admin",
    element: (
      <AuthGuard>
        <PrimaryLayouts />
      </AuthGuard>
    ),
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "user-management",
        element: <UserManagement />,
      },
      
      {
        path: "setting",
        element: <ProfileSetting />,
      },
      
      {
        path: "quiz",
        element: <Quiz />,
      },
      {
        path: "donation",
        element: <Donation />,
      },
      {
        path: "order",
        element: <Order />,
      },
      {
        path: "QuizLeaderBoard",
        element: <QuizLeaderBoard />,
      },
       {
        path: "collectible",
        element: <Collective />,
      },
      
      {
        path: "collectibleType",
        element: <CollectibleType />,
      },
      {
        path:"sub-category",
        element: <SubColletibleType/> 
      },
      {
        path: "product",
        element: <Product/>,
      },
      {
        path: "thali",
        element: <Thali/>,
      },
      {
        path: "contacts",
        element: <Contact/>,
      },
     
    ],
  },
]);

const Routes = () => {
  return (
    <Suspense fallback={<LoaderUi />} >
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;
