import React from "react";
import "./Deletemodal.scss";
import CommonModal from "../CommonModal/CommonModal";
import { DeleteIcon } from "../../../../../assets/images/svg/SvgIcon";
import CommonBtn from "../../CommonBtn/CommonBtn";

const DeleteModal = ({ show, onHide, onSubmit, title }) => {
  return (
    <>
      <CommonModal show={show} onHide={onHide} className="delete_modal">
        <div className="delete_modal_content">
          <DeleteIcon />
          <p>{title}</p>
          <div className="btn_sec">
            <CommonBtn
              role="btn"
              title="No"
              onClick={onHide}
            />
            <CommonBtn
              role="btn"
              title="Yes"
              onClick={onSubmit}
            />
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default DeleteModal;
