import React, { memo, useEffect, useState } from "react";
import "../UserManagement/UserManagement.scss";
import { Col, Container, Row } from "react-bootstrap";
import CommonTable from "../../../common/ui/CommonTable/CommonTable";
import { apiCallGet, apiCallPost } from "../../../../axios/axios";
import {
  checkUndefiendValue, getStatus,
} from "../../../../utils/utils";
import {
  API_DATA_LIMIT, PAGES, PLACEHOLDER_TEXT,
} from "../../../../utils/constants";
import PaginationWrapper from "../../../common/ui/PaginationWrapper/PaginationWrapper";
import moment from "moment";
import { useSelector } from "react-redux";
import Filter from "../../../common/Filter/Filter";

const Thali = () => {

  const [donationList, setThaliList] = useState("")
  const [offset, setOffset] = useState(1);
  const { thali } = useSelector((state) => state.filters);

  const statusOptions = [
    { id: 1, name: "VIRTUAL" },
    { id: 2, name: "PHYSICAL" },
  ];

 

  const getThaliList = async () => {
    let payload = {
        search: thali?.type 
      };
    let res = await apiCallPost(
      `/api/v1/satyug/orders/thali/${API_DATA_LIMIT}/${offset}`,
      payload,
      {},
      false,
      false
    );
    if (!res?.error) {
        setThaliList(res?.data);
    }
  };

  useEffect(() => {
    getThaliList()
  }, [offset])

  useEffect(() => {
    getThaliList()
    setOffset(1)
  }, [thali])

  return (
    <>
      <section className="user_management">
        <Container fluid>
          <Row>
            <Col>
            <Filter
                selectors={[
                    {
                      label: "Type",
                      options: statusOptions,
                      defaultOption: "Select Type",
                      name: "type",
                    },
            ]}
                page={PAGES.thali}
                placeholdertext={PLACEHOLDER_TEXT.searchorder}
              />
              <CommonTable serialised={true} className="user_management_table">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Thali  Amount </th>
                    <th>Buy By</th>
                    <th>Phone No.</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Thali Type</th>
                    <th>Status</th>          
                  </tr>
                </thead>
                <tbody>

                  {donationList?.count > 0 ? (
                    donationList?.rows?.map((item, index) => (
                      <tr key={index}>
                        <td>{offset * 10 - 10 + index + 1}</td>
                        {item?.price === "0" ?
                          <td>
                             Free
                          </td>
                          :
                        <td> 
                          ₹ {checkUndefiendValue(item?.price, '--')}
                        </td>
                            }
                        <td>
                          {checkUndefiendValue(item?.User?.name, '--')}
                        </td>
                        <td>{checkUndefiendValue(item?.phone , '--')}</td>
                        <td>{checkUndefiendValue(moment(item?.createdAt).format("DD-MM-YYYY"), '--')}</td>
                        <td>
                          {checkUndefiendValue(item?.description, '--')}
                        </td>
                        <td>
                          {checkUndefiendValue(item?.thaliType, '--')}
                        </td>
                        <td>{checkUndefiendValue(getStatus(item?.status)?.status)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8} className="no_data_found">
                        No data found
                      </td>
                    </tr>
                  )}

                </tbody>
              </CommonTable>
              {donationList?.count > 0 && (
                <div className="page_nation">
                  <PaginationWrapper
                    limit={API_DATA_LIMIT}
                    page={offset}
                    count={donationList?.count}
                    onChange={(e) => setOffset(e)}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default memo(Thali);
