import React, { useState } from "react";
import "./PrimaryLayouts.scss";
import LeftSidebar from "../../ui/LeftSidebar/LeftSidebar";
import { Header } from "../../ui";
import { Outlet } from "react-router-dom";

const PrimaryLayouts = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleSidebar = () => setShowSidebar(!showSidebar);

  return (
    <main className={`primary_layout`}>
      <LeftSidebar showSidebar={showSidebar} handleSidebar={handleSidebar} />
      <Header showSidebar={showSidebar} handleSidebar={handleSidebar} />
      <div className="primary_layout__right__pages">
        <Outlet />
      </div>
    </main>
  );
};

export default PrimaryLayouts;
