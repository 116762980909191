import React from "react";
import { Field, ErrorMessage } from "formik";
import Form from "react-bootstrap/Form";
import SelectPicker from "react-select";
import styles from "./Select2.module.scss";
import TextError from "../TextError/TextError";

const Select2 = (props) => {
  const {
    name,
    formik,
    label,
    options,
    value,
    onChange,
    placeholder,
    className,
    disabled,
    ...rest
  } = props;
  const defaultValue = (options, value) => {
    return options ? options.find((option) => option.label === value) : "";
  };
  return (
    <Form.Group
      className={`${styles.select} ${className || ""} ${formik.values[name] ? "hasFilled" : ""
        } ${formik?.touched[name] && formik?.errors[name] ? "hasError" : ""}`}
      controlId={name}
    >
      <Form.Label>{label}</Form.Label>
      <Field name={name} {...rest}>
        {() => {
          return (
            <SelectPicker
              classNamePrefix="react-select"
              placeholder={placeholder}
              value={defaultValue(options, value)}
              onChange={(value) => {
                onChange(value)
              }}
              options={options}
              isDisabled={disabled}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </Form.Group>
  );
};

export default Select2;