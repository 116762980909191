import React from "react";

// public pages

// auth pages

const Login = React.lazy(() => import("./public/AuthPages/Login"));
const OtpVerification = React.lazy(() =>
  import("./public/AuthPages/OtpVerification")
);
const Quiz = React.lazy(() => import("./Private/Quiz/Quiz"));
const ChangePassword = React.lazy(() =>
  import("./public/AuthPages/ChangePassword")
);
const ErrorPage = React.lazy(() => import("./public/ErrorPage"));

// private pages
const Dashboard = React.lazy(() => import("./Private/Dashboard/Dashboard"));

const ProfileSetting = React.lazy(() =>
  import("./Private/ProfileSetting/ProfileSetting")
);

const UserManagement = React.lazy(() =>
  import("./Private/UserManagement/UserManagement")
);
const Donation = React.lazy(() =>
  import("./Private/Donation/Donation")
);


const NFTManagement = React.lazy(() =>
  import("./Private/Collective/Collective")
);
const BlogManagement = React.lazy(() =>
  import("./Private/Product/Product")
);


const Order = React.lazy(() =>
  import("./Private/Order/Order")
);
const QuizLeaderBoard = React.lazy(() =>
  import("./Private/QuizLeaderBoard/QuizLeaderBoard")
);
export {
  Login,
  OtpVerification,
  ChangePassword,
  Dashboard,
  ProfileSetting,
  UserManagement,
  NFTManagement,
  BlogManagement,
  ErrorPage,
  Quiz,
  Donation,
  Order,
  QuizLeaderBoard
};
