import React, { useEffect, useState } from "react";
import "./Collective.scss";
import Tab from "react-bootstrap/Tab";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { apiCallGet } from "../../../../axios/axios";
import {
  API_DATA_LIMIT,
  NFT_DATA_LIMIT,
  PAGES,
} from "../../../../utils/constants";
import PaginationWrapper from "../../../common/ui/PaginationWrapper/PaginationWrapper";
import CollectiveListTab from "./CollectiveListTab/CollectiveListTab";
import Filter from "../../../common/Filter/Filter";
import CollectibleModal from "../../../common/ui/Modals/CollectibleModal/CollectibleModal";

const CollectiveTab = () => {
  const [listNft, setListNft] = useState([]);
  const [offset, setOffset] = useState(1);
  const [activeTab, setActiveTab] = useState("list");
  const [showAdd, setShowAdd] = useState(false);
  const nft = useSelector((state) => state.filters.nft);
  const itemsPerPage = 10;

  const getCollectible = async () => {
    let res = await apiCallGet(`/api/v1/satyug/collectable`, {}, false, true);

    if (!res?.error) {
      setListNft(res?.data);
    }
  };

  useEffect(() => {
    getCollectible();
  }, [nft, activeTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const onBlogSuccessCallback = () => {
    getCollectible();
  };

  // Paginate the data based on offset and itemsPerPage
  const paginatedData = listNft.slice(
    (offset - 1) * itemsPerPage,
    offset * itemsPerPage
  );

  return (
    <div className="nft_tab">
      <Filter
        isButton
        onClick={() => setShowAdd(true)}
        title={<p>Add Collectible</p>}
        page={PAGES.collectible}
      />
      <Tab.Content>
        <CollectiveListTab listNft={paginatedData} getCollectible={getCollectible} offset={offset} />
      </Tab.Content>
      {showAdd && (
        <CollectibleModal
          show={showAdd}
          onHide={() => setShowAdd(false)}
          onSuccess={onBlogSuccessCallback}
        />
      )}

      {listNft?.length > itemsPerPage && (
        <div className="page_nation">
          <PaginationWrapper
            limit={itemsPerPage}
            page={offset}
            count={listNft?.length}
            onChange={(e) => setOffset(e)}
          />
        </div>
      )}
    </div>
  );
};

export default CollectiveTab;
