import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./LeftSidebar.scss";
import {
  BannerIcon,
  CreateNftIcon,
  DashboardIcon,
  KYCIcon,
  NftIcon,
  RefferalIcon,
  SubAdminIcon,
  UserIcon,
  Collectible,
  Quiz,
  Order,
  Donation,
  Product,
  ReportIcon,
  CollectibleTypeIcon,
  ThaliIcon,
} from "../../../../assets/images/svg/SvgIcon";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import logo from "../../../../assets/images/img/logo.png";
import CollectibleType from "../../../pages/Private/CollectibleType/CollectibleType";
import { S3_BUCKET } from "../../../../utils/constants";


const LeftSidebar = ({ showSidebar, handleSidebar }) => {
  const { role } = useSelector((state) => state.user);
  const initialValues = {
    mail: "",
    password: "",
    startDate: "",
    endDate: "",
    toggle: false,
  };

  const validationSchema = Yup.object({
    mail: Yup.string().required("You have entered a wrong email"),
    password: Yup.string().required("You have entered a wrong password"),
    check: Yup.string().required("Please click on checkbox before login"),
    startDate: Yup.string().required("Please enter a correct start date"),
    endDate: Yup.string().required("Please enter a correct end date"),
  });
  const onSubmit = (values) => {
    console.log("overall-target ====>", values);
  };
  const options = [
    {
      label: "City",
      defaultOption: "City",
    },
    {
      label: "City",
      defaultOption: "City",
    },
  ];

  const [defaultSelectText, setDefaultSelectText] = useState("Dashboard List");

  const [countryList, setCountryList] = useState([
    { id: 1, name: "Dashboard List1" },
    { id: 2, name: "Dashboard List2" },
    { id: 3, name: "Dashboard List3" },
  ]);

  const Headerdata = [
    {
      home_icon: <DashboardIcon />,
      title: "Dashboard",
      to: "/admin/dashboard",
    },
    {
      home_icon: <UserIcon />,
      title: "User Management",
      to: "/admin/user-management",
    },
    // {
    //   home_icon: <SubAdminIcon />,
    //   title: "Sub-Admin Management",
    //   to: "/admin/subadmin-management",
    //   restrictedFor: ROLES.subAdmin,
    // },
    {
      home_icon: <Collectible />,
      title: "Collectible",
      to: "/admin/collectible",
    },
    {
      home_icon: <CollectibleTypeIcon />,
      title: "Collectible Type",
      to: "/admin/collectibleType",
    },
    {
      home_icon: <Quiz />,
      title: "Quiz",
      to: "/admin/Quiz",
    },
    {
      home_icon: <Order />,
      title: "Order",
      to: "/admin/order",
    },
    {
      home_icon: <Donation />,
      title: "Donation",
      to: "/admin/donation",
    },
    {
      home_icon: <ThaliIcon />,
      title: "Thali",
      to: "/admin/thali",
    },
    // {
    //   home_icon:  <KYCIcon />,
    //   title: "Quiz LeaderBoard",
    //   to: "/admin/QuizLeaderBoard",
    // },
    // {
    //   home_icon: <CreateNftIcon />,
    //   title: "Create NFTs",
    //   to: "/admin/create-nft",
    // },
    // {
    //   home_icon: <FeaturedIcon />,
    //   title: "Featured RSTs",
    //   to: "/admin/featured-rst",
    // },
    // {
    //   home_icon: <KYCIcon />,
    //   title: "KYC Management",
    //   to: "/admin/kyc-management",
    // },
    // {
    //   home_icon: <DashboardIcon />,
    //   title: "Listing History",
    //   to: "/admin/listing-history",
    // },
    // {
    //   home_icon: <RefferalIcon />,
    //   title: "Referral & Commission",
    //   to: "/admin/refferal-commission",
    // },
    // {
    //   home_icon: <FeeIcon />,
    //   title: "Fee Management",
    //   to: "/admin/fee-management",
    // },
    // {
    //   home_icon: <BannerIcon />,
    //   title: "Banner Management",
    //   to: "/admin/banner-management",
    // },
    // {
    //   home_icon: <FeedbackIcon />,
    //   title: "Feedback Received",
    //   to: "/admin/feeback-received",
    // },
    {
      home_icon: <Product />,
      title: "Product",
      to: "/admin/product",
    },
    {
      home_icon: <ReportIcon />,
      title: "Query",
      to: "/admin/contacts",
    },
  ];

  return (
    <>
      <div onClick={handleSidebar} className={`sidebar_overlay ${showSidebar ? "show" : ""}`} />
      <div className={`Admin_leftside_layout ${showSidebar ? "active" : ""}`}>
        <div className="sidebar_logo">
          <Link to="/">
            <img src={S3_BUCKET.DIGITAL_LOGO} alt="" />
            {/* <h2>Satyug</h2> */}
          </Link>
        </div>
        <div className="header_links">
          {/* <PerfectScrollbar> */}
          <ul>
            {Headerdata.map((data, index) => {
              return (
                (
                  <li key={index}>
                    <NavLink onClick={handleSidebar} to={data.to}>
                      <span>{data.home_icon}</span>
                      {data.title}
                    </NavLink>
                  </li>
                )
              );
            })}
            {/* <div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => (
                  <Form className="admin_form">
                    <FormikControls
                      defaultText={defaultSelectText}
                      optionsList={countryList}
                      control="select"
                      type="text"
                      formik={formik}
                      name="documentType"
                      variant="sidebar_select"
                    />
                  </Form>
                )}
              </Formik>
            </div> */}
          </ul>
          {/* </PerfectScrollbar> */}
        </div>
      </div>
      {/* <button
        className={`toggleBtn ${active ? "active" : ""}`}
        onClick={() => setActive(!active)}
      >
        <span className="menu-line"></span>
        <span className="menu-line"></span>
        <span className="menu-line"></span>
      </button> */}
    </>
  );
};

export default LeftSidebar;
