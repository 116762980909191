import React, { memo, useEffect, useState } from "react";
import "./CollectibleModal.scss";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import CommonModal from "../CommonModal/CommonModal";
import FormikControls from "../../Formik/FormikControls";
import { UploadIconNew } from "../../../../../assets/images/svg/SvgIcon";
import CommonBtn from "../../CommonBtn/CommonBtn";

import {
  apiCallGet,
  apiCallPatch,
  apiCallPost,
} from "../../../../../axios/axios";
import { toasts } from "../../Toast/Toast";
import { Col, Row } from "react-bootstrap";


const CollectibleModal = ({ show, onHide, onSuccess, isUpdate, combinedProducts }) => {

  const [selectedType, setSelectedType] = useState('');
  const [imageSrc, setImageSrc] = useState(null);
  const [artiveSrc, setArtiveSrc] = useState(null);

  const title = isUpdate ? "Update" : "Add";

  // states
  const [blogImg, setBlogImg] = useState("");
  const [artiveImg, setArtiveImg] = useState("");
  const [blogData, setBlogData] = useState("");
  const [type, setType] = useState("");

  const initialValues = {
    collectible: blogData?.title || "",
    description: blogData?.description || "",
    subCollectible: blogData?.type || "",
    srNo:blogData?.srNo || "",
    category : blogData?.collectableTypeId || ""
  };
  const validationSchema = Yup.object({
    collectible: Yup.string()
      .required("Please enter collectible")
      .min("2", "Collectible must be between 2-40 characters")
      .max("40", "Collectible must be between 2-40 characters"),
      description: Yup.string()
      .required("Please enter description")
      .min("2", "description must be between 2-100 characters")
      .max("100", "description must be between 2-100 characters"),
      category:Yup.string()
      .required('Please Select Category'),
      srNo:Yup.string()
      .required("Please enter sr. no."),
  });

  // upload image
  async function handleImageUpload(e, formik) {
    try {
      const file = e.target.files[0];
      if (file.size <= 5 * 1024 * 1024) {
        let data = new FormData();
        data.append("type", "collective_p2");
        data.append("upload", file);

        const result = await apiCallPost(
          "/api/v1/satyug/file/upload",
          data,
          {},
          true,
          true
        );

        if (result && !result.error) {
          let img = result?.data?.image;
          setBlogImg(img);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleArtiviveUpload(e, formik) {
    try {
      const file = e.target.files[0];
      if (file.size <= 5 * 1024 * 1024) {
        let data = new FormData();
        data.append("type", "artivive_p2");
        data.append("upload", file);

        const result = await apiCallPost(
          "/api/v1/satyug/file/upload",
          data,
          {},
          true,
          true
        );

        if (result && !result.error) {
          let img = result?.data?.image;
          setArtiveImg(img);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  // add blog
  const addBlog = async (values) => {
    let payload = {
      image: blogImg,
      artivive:   artiveImg ,
      collectableTypeId : values.category,
      title: values.collectible,
      description: values.description,
      srNo:values.srNo
    };
    let res = await apiCallPost(`/api/v1/satyug/collectable`, payload, {}, true, true);
    if (!res?.error) {
      onHide();
      onSuccess();
    }
  };
  // update blog
  const updateBlog = async (values) => {
    let payload = {
      image: blogImg || blogData?.image,
      artivive:   artiveImg  || blogData?.artivive,
      collectableTypeId : values.category,
      title: values.collectible,
      description: values.description,
      srNo:values.srNo
    };
    let res = await apiCallPatch(`/api/v1/satyug/collectable/${show?.id}`, payload, {}, true, true);
    if (!res?.error) {
      onHide();
      onSuccess();
    }
  };

  const onSubmit = (values) => {
    if (isUpdate) {
      updateBlog(values);
    } else {
      if (blogImg || artiveImg) {
        addBlog(values);
      } else {
        toasts.error("Please upload image");
      }
    }
  };

  // get blog by id
  const getBlogById = async () => {
    let res = await apiCallGet(`api/v1/satyug/collectable/single/${show?.id}`, {}, false, true);
    if (!res?.error) {
      setBlogData(res?.data);
    }
  };

  useEffect(() => {
    isUpdate && getBlogById();
  }, []);

  // const onBlogSuccessCallback = () => {
  //   if (selectedType === "type cap") {
  //     // setSelectedSize("");
  //     setSelectedType("");
  //   }
  //   // getproductList();
  // };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  useEffect(() => {
    if (blogImg) {
      // If blogImg is available, set the imageSrc to it
      setImageSrc(blogImg);
    } else {
      // If blogImg is not available, set the imageSrc to the first blogData image (if available)
      setImageSrc(blogData?.image || null);
    }
  }, [blogImg, blogData]);

  useEffect(() => {
    if (artiveImg) {
      // If blogImg is available, set the imageSrc to it
      setArtiveSrc(artiveImg);
    } else {
      // If blogImg is not available, set the imageSrc to the first blogData image (if available)
      setArtiveSrc(blogData?.image || null);
    }
  }, [artiveImg, blogData]);

  const getCategoryList = async () => {
    let res = await apiCallPost(
      `/api/v1/satyug/collectableType/${'100'}/${'1'}`,
      {},
      false,
      false
    );
    if (!res?.error) {
      const formattedData = res?.data?.rows.map((type) => ({
        label: type.name,
        value: type.id,
      }));

      // Now 'formattedData' contains the desired format
      setType(formattedData);
    }
  };
  useEffect(() => {
    getCategoryList()
  }, [])

  console.log('type',blogData?.collectableType?.name);
  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        className="product_modal no_btn"
        heading={`${title} Collectible`}
      >
        <div className="product_modal_content">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <Row>
                  {imageSrc ?
                    <Col>
                      <div className="updateBlogImage">
                        <img src={blogImg || blogData?.image}></img>
                      </div>
                    </Col>
                    : ""}
                  <Col>
                    <div className="product_modal_content_item">
                      <UploadIconNew />
                      <h3>Upload Collectible Image Here</h3>
                      <FormikControls
                        className="upload_sec"
                        label="Add Collectible Image"
                        control="input"
                        type="file"
                        onBlur={formik.handleBlur}
                        formik={formik}
                        name="blogImg"
                        accept="image/png, image/jpeg"
                        onChange={(e) => handleImageUpload(e, formik)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {artiveSrc ?
                    <Col>
                      <div className="updateBlogImage">
                        <img src={artiveImg || blogData?.artivive}></img>
                      </div>
                    </Col>
                    : ""}
                  <Col>
                    <div className="product_modal_content_item">
                      <UploadIconNew />
                      <h3>Upload Artivive Image Here</h3>
                      <FormikControls
                        className="upload_sec"
                        label="Add Artivive Image"
                        control="input"
                        type="file"
                        // onBlur={formik.handleBlur}
                        formik={formik}
                        name="artiviveImg"
                        accept="image/png, image/jpeg"
                        onChange={(e) => handleArtiviveUpload(e, formik)}
                      />
                    </div>
                  </Col>
                </Row>
                <FormikControls
                  label="Sr.no."
                  placeholder="Enter Serial Number"
                  control="input"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.srNo}
                  formik={formik}
                  name="srNo"
                />
                <FormikControls
                  label="Collectible"
                  placeholder="Enter Collectible"
                  control="input"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.collectible}
                  formik={formik}
                  name="collectible"
                />
                 <FormikControls
                  label="Category"
                  // defaultText="Select Category"
                  placeholder={
                    isUpdate === true
                      ?  blogData?.collectableType?.name
                      : "Select Category"
                  }
                  control="select2"
                  type="text"
                  onChange={(value) => {
                    formik.setFieldValue("category", value.value );
                  }}
                  onBlur={formik.handleBlur}
                  options = {type}
                  value={formik.values.category}
                  formik={formik}
                  name="category"
                />
                 <FormikControls
                  label="Description"
                  placeholder="Enter Description"
                  control="input"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  formik={formik}
                  name="description"
                />
                {/* <FormikControls
                  label="Artivive"
                  placeholder="Enter Artivive Link"
                  control="input"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.artivive}
                  formik={formik}
                  name="artivive"
                /> */}
                <div className="btn_sec text-center d-sm-flex justify-content-between flex-row-reverse">
                  <CommonBtn
                    className="mb-sm-0 mb-3"
                    role="btn"
                    title={title}
                    disabled={!formik.isValid}
                  />
                  <CommonBtn
                    className="disable-btn"
                    role="btn"
                    title="Cancel"
                    type="button"
                    onClick={onHide}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </CommonModal>
    </>
  );
};

export default memo(CollectibleModal);
