import React from "react";
import "./ImageModal.scss";
import CommonModal from "../CommonModal/CommonModal";

const ImageModal = ({ show, onHide, hotelDetail, image }) => {

  return (
    <CommonModal show={show} onHide={onHide} className="image-modal">
      <div className="image-modal_wrap">
          <>
              <div className="image-modal_img">
                {image ? (
                  <img
                    className="image-cover"
                    src={image}
                    alt="hotel-img"
                  />
                ) : (
                 ""
                )}
              </div>
          </>
      </div>
    </CommonModal>
  );
};

export default ImageModal;
