import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { AuthLayout } from "../../../common/layouts";
import { CommonBtn, FormikControls } from "../../../common/ui";
import login_man_img from "../../../../assets/images/img/login_man_img.png";
import otp_vector from "../../../../assets/images/img/otp_vector.png";
import "./PagesStyle.scss";
import { EyeCloseIcon, EyeOpenIcon } from "../../../../assets/images/svg/SvgIcon";
import { FORMIK_REGEX } from "../../../../utils/constants";
import { apiCallPatch, apiCallPost } from "../../../../axios/axios";
import { useNavigate } from "react-router-dom";
import { useToggle } from "../../../../hooks/useToggle";

const ForgotPassword = () => {
  //   STATES
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(1);
  const [isNewPassText, setIsNewPassText] = useState(false);
  const [isConfirmPassText, setIsConfirmPassText] = useState(false);
  const [showConfirmPassword, toggleConfirmPassword] = useToggle(false);
  const [showNewPassword, toggleNewPassword] = useToggle(false);
  //   HOOK
  const navigate = useNavigate();

  // CONSTANTS
  const pageTitle = `${step == 1
    ? "Email Verification"
    : step == 2
      ? "OTP Verification"
      : "Change Password"
    }`;
  const text =
    step == 2 && `We have sent an OTP to the email address: ${email}`;
  const mainImg = step == 2 ? otp_vector : login_man_img;

  //   EMAIL VERIFICATION
  const emailInitialValues = {
    email: "",
  };
  const emailValidationSchema = Yup.object({
    email: Yup.string()
      .required("Please enter your email")
      .email("Please enter a valid email address")
      .matches(FORMIK_REGEX.EMAIL_REGEX, "Please enter a valid email address"),
  });
  const confirmEmail = async (values) => {
    let payload = {
      email: values?.email,
    };
    let res = await apiCallPost(
      "/api/v1/satyug/auth/forgotPassword",
      payload,
      {},
      true,
      true
    );
    if (!res?.error) {
      setEmail(payload?.email);
      setStep(2);
    }
  };

  //   OTP VERIFICATION
  const otpInitialValues = {
    otp: "",
  };
  const otpValidationSchema = Yup.object({
    otp: Yup.string()
      .required("Please enter otp")
      .min(
        6,
        "The OTP (One-Time Password) should consist of a minimum of six digits. "
      ),
  });

  const verifyOtp = async (values) => {
    let payload = {
      email: email,
      otp: values?.otp || "",
    };
    let res = await apiCallPost(
      "/api/v1/satyug/auth/verify-otp",
      payload,
      {},
      true,
      true
    );
    if (!res?.error) {
      setStep(3);
    }
  };

  //   SET PASSWORD
  const setPassInitialValues = {
    password: "",
    confirmPassword: "",
  };
  const setPassValidationSchema = Yup.object({
    password: Yup.string()
      .required("Please Enter your password")
      .matches(/^\S*$/, "Whitespace is not allowed")
      .max(15)
      .matches(
        FORMIK_REGEX.PASSWORD_REGEX,
        "Must Contain 6 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character"
      ),
    confirmPassword: Yup.string()
      .required("Please add confirm password")
      .oneOf([Yup.ref("password"), null], "Password must match"),
  });
  const setNewPassword = async (values) => {
    let payload = {
      email: email,
      newPassword: values?.password,
      confirmPassword: values?.confirmPassword,
    };
    let res = await apiCallPatch(
      "/api/v1/satyug/auth/forgotPasswordChange",
      payload,
      {},
      true,
      true
    );
    if (!res?.error) {
      navigate("/");
    }
  };

  // SUBMIT HANDLER
  const onSubmit = (values, form) => {
    switch (form) {
      case "email":
        confirmEmail(values);
        break;
      case "otp":
        verifyOtp(values);
        break;
      case "setPass":
        setNewPassword(values);
        break;
      default:
        break;
    }
  };

  return (
    <AuthLayout
      className="login-page change_pwd"
      mainImg={mainImg}
      alt="login_man_img"
      title={pageTitle}
      text={text}
    >
      {step == 1 && (
        <Formik
          initialValues={emailInitialValues}
          validationSchema={emailValidationSchema}
          onSubmit={(values) => onSubmit(values, "email")}
        >
          {(formik) => (
            <Form>
              <Row>
                <Col xs={12}>
                  <FormikControls
                    label="Email"
                    placeholder="Enter your Email"
                    control="input"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    formik={formik}
                    name="email"
                  />
                </Col>
                <Col xs={12}>
                  <CommonBtn
                    title="Confirm"
                    role="btn"
                    disabled={!formik.dirty || !formik?.isValid}
                    className="mx-auto d-block"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
      {step == 2 && (
        <Formik
          initialValues={otpInitialValues}
          validationSchema={otpValidationSchema}
          onSubmit={(values) => onSubmit(values, "otp")}
        >
          {(formik) => (
            <Form>
              <Row>
                <Col xs={12}>
                  <FormikControls
                    label="Enter Your 6 Digit OTP"
                    control="otp-input"
                    className="otp_input"
                    formik={formik}
                    name="otp"
                    onChange={(value) => {
                      formik.setFieldValue("otp", value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.otp}
                  />
                </Col>
                <Col xs={12}>
                  <CommonBtn
                    title="Submit"
                    role="btn"
                    className="mx-auto d-block"
                    disabled={!formik.dirty || !formik?.isValid}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
      {step == 3 && (
        <Formik
          initialValues={setPassInitialValues}
          validationSchema={setPassValidationSchema}
          onSubmit={(values) => onSubmit(values, "setPass")}
        >
          {(formik) => (
            <Form>
              <Row>
                <Col xs={12}>
                  <div className="group_form">
                    <FormikControls
                      label="Enter New Password"
                      placeholder="Enter new password"
                      control="input"
                      type={showConfirmPassword ? "text" : "password"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      formik={formik}
                      name="password"
                      rightIconClick={() => toggleConfirmPassword()}
                        rightIcon={showConfirmPassword ? <span style={{cursor: "pointer"}}><EyeOpenIcon /></span> : <span style={{cursor: "pointer"}}>
                        <EyeCloseIcon /></span>}
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="group_form">
                    <FormikControls
                      label="Confirm Password"
                      placeholder="Re-enter Your Password"
                      control="input"                   
                      type={showNewPassword ? "text" : "password"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                      formik={formik}
                      name="confirmPassword"
                      rightIconClick={() => toggleNewPassword()}
                        rightIcon={showNewPassword ? <span style={{cursor: "pointer"}}><EyeOpenIcon /></span> : <span style={{cursor: "pointer"}}>
                        <EyeCloseIcon /></span>}
                    />          
                            </div>
                </Col>
                <Col xs={12}>
                  <CommonBtn
                    title="Confirm"
                    role="btn"
                    disabled={!formik.dirty || !formik?.isValid}
                    className="mx-auto d-block"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </AuthLayout>
  );
};

export default ForgotPassword;
